<template>
  <div>
    <join-card heading="Join to Retina"  :include-name="true">

      <div class="text-red-400 w-full max-w-xs mx-auto text-center mt-4">{{ formMessage }}</div>

      <status-message :response="loginResponse" timeout="6000"></status-message>
      <status-message :response="otpResponse" timeout="6000"></status-message>

      <form class="w-full" action="" @submit.prevent="submitForm">
        <div class="my-6 w-full">
          <div class="my-6 w-full">
            <input
                autofocus
                :type="$route.query.phone ? 'hidden':'text'"
                placeholder="Enter phone number : 017XXXXXXXX"
                v-model="userdata.phone"
                class="
                text-center
                block
                w-full
                max-w-xs
                mx-auto
                border-2
                rounded-lg
                focus:outline-none
                p-2
              "
            />
            <show-error :errors="formErrors" name="phone" class="text-center"></show-error>
          </div>
          <div class="
                flex flex-col
                justify-center
                block
                w-full
                max-w-xs
                mx-auto ">

            <input-text size="md" type="password" autofocus password-eye
                        v-model="userdata.password"
                        input-class="text-center"
                        class="flex-grow" placeholder="Enter Your Password"></input-text>
            <show-error :errors="this.formErrors" name="password" class="text-sm text-center w-full"></show-error>
          </div>
        </div>
        <div class="text-center">
          <btn type="submit" variant="info" :loading="loading">Join</btn>
        </div>

        <div class="w-full mt-4 flex flex-col">
          <div class="w-full text-center">
            <span>Forgot your password?</span>
            <a href="" @click.prevent="sendForgetPasswordRequest" class="ml-3 text-blue-500 hover:text-blue-800">Click Here</a>
          </div>
          <status-message :background="false" :response="otpResponse" class="text-center"/>
        </div>

      </form>
    </join-card>
  </div>
</template>

<script>
import JoinCard from "../components/joincard.vue";
import InputText from "../../../../components/input/input-text"
import Btn from "../../../../components/btn";
import ShowError from "../../../../components/show-error";
import StatusMessage from "../../../../components/status-message";

export default {
  components: {StatusMessage, ShowError, Btn, JoinCard, InputText },
  name: "Login",
  data() {
    return {
      loginResponse: {  },
      otpResponse: {  },
      otpSendingStatus: 200,
      forgot_pass_message: '',
      loading: false,
      checkingUser: false,
      userdata: {phone: this.$route.query.phone, password: ''},
      password_input_type: 'password',
    }
  },

  methods: {
    sendForgetPasswordRequest( ){
      this.forgot_pass_message = 'Sending...'
      this.checkingUser = true;
      this.otpSendingStatus = 200;
      //this.otpResponse = {loading: 'Requesting...'};

      this.$store.dispatch( 'student/checkUser',
          {
            data: { phone: this.userdata.phone },
            error: response => this.setFormMessage( response.data.message ),
            has_errors: errors => this.setFormErrors( errors ),
            response:  res => this.otpResponse = res,
            success: ( response ) => {
              const data = response.data;
              this.setFormMessage( data.message );
              if( data.has_user ) {
                this.$router.push( '/forgot-password?phone=' + this.userdata.phone );
              } else {
                this.forgot_pass_message = 'Your number is not registered.'
              }
            }
          }
      ).finally( () => this.checkingUser = false );
    },
    submitForm() {
      this.loading = true;
      this.formErrors = [ ];
      //this.loginResponse = {loading: 'Logging In...'};

      this.$store.dispatch( 'student/login',
          {
            data: this.userdata,
            // error: response => this.response = response,
            response:  res => this.loginResponse = res,
            has_errors: errors => this.setFormErrors(errors),
            success: (response) => {
              const data = response.data;
              this.setFormMessage( data.message );
              this.setFormMessage(data.message, 200);
              this.setTokenAndRedirectToNext(data);
            }

          }
      ).finally( () => this.loading = false );

    }
  }
};
</script>

